<template>
  <div>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="bread" large></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" class="float-right mr-3" @click="goBack">
          Volver
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <div class="mb-5">
              <div class="row">
                <div class="col-lg-5" style="width: 20%">
                  <div
                    class="d-flex align-center justify-space-between"
                    style="height: 40px"
                  >
                    <div class="d-flex align-center">
                      <span class="mr-3" style="width: 70px">Nombre:</span>
                      <h3>
                        {{ cliente.nombre }}
                        {{ cliente.apellido }}
                      </h3>
                    </div>
                  </div>
                  <div
                    class="d-flex align-center justify-space-between"
                    style="height: 40px"
                  >
                    <div class="d-flex align-center">
                      <span class="mr-3" style="width: 80px">DNI/CUIT:</span>
                      <h3>
                        {{ cliente.cuit ? cliente.cuit : cliente.dni }}
                      </h3>
                    </div>
                  </div>
                  <div class="d-flex align-end" style="height: 50px">
                    <span class="mr-3" style="width: 80px">Operatoria:</span>
                    <h2 class="titulo-descrip">
                      {{ operatoria.nombre }}
                    </h2>
                  </div>
                  <div class="d-flex align-end" style="height: 50px">
                    <span class="mr-3" style="width: 90px">Fideicomiso:</span>
                    <h2 class="titulo-descrip">
                      {{ this.operatoria.fideicomiso_id.nombre }}
                    </h2>
                  </div>
                  <div class="d-flex align-end" style="height: 50px">
                    <span class="mr-3" style="width: 140px"
                      >Fecha Desembolso:</span
                    >
                    <h3>{{ fechaDesembolso || desembolso || '' }}</h3>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="d-flex align-end" style="height: 50px">
                    <span class="mr-3" style="width: 80px">Estado:</span>
                    <h3>
                      <span v-if="estadoMora !== ''" class="titulo-descrip"
                        >{{
                          estadoMora === 'EN MORA' && this.tipoMora !== ''
                            ? tipoMora
                            : estadoMora
                        }}
                        {{
                          estadoMora === 'CANCELADO' || estadoMora === undefined
                            ? ''
                            : `(${diasatraso} días)`
                        }}</span
                      >
                    </h3>
                  </div>
                  <div class="d-flex align-end" style="height: 50px">
                    <span class="mr-3" style="width: 90px">Saldo Capital:</span>
                    <h3>
                      <span class="titulo-descrip">
                        $
                        {{ saldocapital }}
                      </span>
                    </h3>
                  </div>
                  <div class="d-flex align-end" style="height: 40px">
                    <span class="mr-3" style="width: 150px"
                      >Estado Manual:</span
                    >
                    <h3>
                      <span v-if="estadoManual !== ''" class="titulo-descrip">{{
                        estadoManual
                      }}</span>
                    </h3>
                  </div>
                  <div class="d-flex align-end" style="height: 40px">
                    <span class="mr-3" style="width: 150px">Observacion:</span>
                    <h3>
                      <span
                        v-if="observaciones !== ''"
                        class="titulo-descrip"
                        >{{ observaciones }}</span
                      >
                    </h3>
                  </div>
                  <div class="d-flex align-end" style="height: 40px">
                    <v-btn
                      v-if="!dialog"
                      style="width: auto"
                      color="primary"
                      @click="abrirModal()"
                      >Estado Manual</v-btn
                    >
                  </div>
                </div>
              </div>

              <div v-if="dialog" class="estado-manual">
                <div
                  class="d-flex align-center justify-space-between"
                  style="height: 40px"
                ></div>
                <div class="card mb-3" style="height: auto">
                  <div class="card-header">
                    <span class="mr-3" style="width: 70px">
                      <div class="d-flex align-end" style="height: 40px">
                        <v-col md="6" offset-md="3">
                          <v-autocomplete
                            v-model="values"
                            :items="tipoManual"
                            auto-select-first
                            clearable
                            deletable-chips
                            small-chips
                            solo
                            label="Seleccione tipo estado manual"
                            outlined
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                      </div>
                    </span>
                  </div>
                  <div class="card-body">
                    <div
                      class="d-flex align-end"
                      style="height: 40px, margin: 0,5px"
                    >
                      <v-text-field
                        label="Observación"
                        v-model="selectObservacion"
                        maxlength="100"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-end" style="height: 40px">
                      <v-btn
                        color="primary"
                        @click="crearEstado()"
                        style="width: auto"
                        >Guardar estado</v-btn
                      >

                      <v-btn
                        style="margin-left: 5px"
                        color="primary"
                        @click="cerrarModal()"
                        >Cerrar</v-btn
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card>
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab v-for="t in tabs" :key="t" :href="`#${t}`"
              >{{ t.charAt(0).toUpperCase() + t.slice(1) }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="t in tabs" :key="t" :value="`${t}`">
              <LiquidacionFinanciamiento
                v-if="
                  t === 'Liquidaciones' && operatoria.tipo_servicio_id === 13
                "
                :reRender="reRender"
                :legajo="lineaCreditoCliente.id"
              >
              </LiquidacionFinanciamiento>
              <CuentaCorriente
                v-if="t === 'Cuenta Corriente'"
                :solicitud_id="solicitud_id"
                :fecha_actual="fechaActual"
                :fecha_ini="fechaInicio[0]"
                :fecha_fin="fechaFin[0]"
              >
              </CuentaCorriente>
              <Legajo
                v-if="t === 'Legajo'"
                :operatoria="operatoria"
                :codcliente="lineaCreditoCliente.codigo_cliente_trilay"
                :legajo="lineaCreditoCliente.id"
                :cliente="cliente"
              ></Legajo>
              <TablaParametros
                v-on:generarCuadro="setCuadro"
                v-if="t === 'Parámetros de Crédito' && loadFlag"
                :parametros="parametros"
                :cliente="cliente.id"
                :solicitud_id="solicitud_id"
                :operatoria_id="lineacredito_id"
                :creditoEstado="creditoEstado"
                :estado="lineaCreditoCliente.estado_id"
                :update="update"
                :fechadesembolso="fechaDesembolso || desembolso || ''"
                :isLiquidado="isLiquidado"
                @generarCuadroLiquidaciones="actualizarLiquidaciones"
              ></TablaParametros>
              <CuadroMarcha
                v-if="
                  t === 'Desarrollo del Crédito' &&
                  typeof solicitud_id !== 'undefined'
                "
                :datos="datos"
                :cliente="cliente"
                :datosParametros="datosParametros"
                :solicitud_id="solicitud_id"
                :operatoria="operatoria"
              ></CuadroMarcha>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import TablaParametros from '@/components/Credito/Pestanias/TablaParametroEjecutivo.vue';
import Legajo from '@/components/Credito/Pestanias/Legajo.vue';
import CuadroMarcha from '@/components/Credito/Pestanias/CuadroMarcha.vue';
import CuentaCorriente from '@/components/Credito/Pestanias/CuentaCorriente.vue';
import LiquidacionFinanciamiento from '@/components/Comprobantes/LiquidacionFinanciamiento.vue';
import axios from 'axios';
import { showSimpleMessage } from '@/common/util';

export default {
  name: 'Credito',
  components: {
    TablaParametros,
    Legajo,
    CuadroMarcha,
    CuentaCorriente,
    LiquidacionFinanciamiento,
  },
  props: {
    lineacredito_id: {
      type: Number,
    },
    cliente: {
      type: Object,
      required: true,
    },
    operatoria: {
      type: Object,
      required: true,
    },
    solicitud_id: {
      type: Number,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    legajo: {
      type: Number,
    },
    fechaDesembolso: { type: String },
  },
  data: () => ({
    isLiquidado: false,
    saldocapital: 0,
    tab: null,
    lineaCreditoCliente: [],
    selectObservacion: '',
    parametros: [],
    fechaInicio: '',
    values: '',
    fechaFin: '',
    reRender: 0,
    datos: [],
    update: '',
    tipoManual: [
      { text: 'CREDITO CAIDO' },
      { text: 'PRORROGADO' },
      { text: 'NOTIFICACION' },
      { text: 'CARTA DOCUMENTO' },
      { text: 'EN JUICIO' },
    ],
    loadFlag: false,
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Ver crédito',
        disabled: true,
        href: '/catalogo',
      },
    ],

    fechaActual: '',
    datosParametros: [],
    creditoEstado: '',
    credestado: '',
    estadoMora: '',
    diasatraso: '',
    estadoManual: '',
    tipoMora: '',
    observaciones: '',
    dialog: false,
    desembolso: '',
    formatter: new Intl.NumberFormat('es-AR', {
      maximumFractionDigits: 2,
    }),
  }),
  methods: {
    actualizarLiquidaciones() {
      this.reRender++;
    },
    goBack() {
      window.history.back();
    },
    setCuadro(datos) {
      this.tab = 'Desarrollo del Crédito';
      this.datos = datos;
    },
    cargarCuadro() {
      const url = `${API_URL}api/historicoCuadroMarcha/${this.$route.params.id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      axios.get(url, header).then((result) => {
        if (result.data.status === 'error') {
          return false;
        }
        this.datos = Object.entries(result.data.historicoCuadro);
        this.update = result.data.origen;
        this.fechaInicio = this.datos[0];
        this.fechaFin = this.datos[this.datos.length - 1];
      });
    },
    guardarClienteParametro(lineaccId) {
      axios
        .post(`${API_URL}api/clienteParametros/editar/`, {
          token: this.token,
          lineaCredito_id: lineaccId,
          datos: this.datosParametros,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            console.log(response);
          }
        });
    },
    async loadParametros() {
      const url = `${API_URL}api/tipoParametros/servicio/${this.lineacredito_id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      await axios.get(url, header).then((result) => {
        this.parametros = result.data;
        this.datosParametros = result.data;
        this.loadFlag = true;
      });
    },
    async getEstadoCredito() {
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const url = `${API_URL}api/creditoEstado/get`;
      const data = {
        lineacreditocliente_id: this.$route.params.id,
      };
      await axios
        .post(url, data, header)
        .then((r) => {
          setTimeout(() => {
            this.credestado = r.data.estado.nombre;
          }, 500);
        })
        .catch((e) => {
          console.error(e);
          return showSimpleMessage(
            'Error: ',
            'Ocurrió un error al obtener el estado del credito',
            'error',
            2000
          );
        });
    },
    async getEstado() {
      const query = {
        method: 'GET',
        headers: {
          token: localStorage.getItem('token'),
        },
        url: `${API_URL}api/solicitudEstado`,
      };
      const {
        data: { status, message, solicitudEstado },
      } = await axios(query);
      if (status === 'success') return solicitudEstado;
      if (status === 'error') console.error(message);
      return null;
    },

    getFechaActual() {
      let fecha = new Date();
      fecha = `${fecha.getDate()}/${
        fecha.getMonth() + 1
      }/${fecha.getFullYear()}`;
      this.fechaActual = fecha;
    },
    async getLineaCredito() {
      const query = {
        method: 'GET',
        url: `${API_URL}api/servicioClientes/${this.$route.params.id}`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const {
        data: { status, message, lineaCreditoCliente, liquidado },
      } = await axios(query);
      if (status === 'success') {
        const estados = await this.getEstado();
        // this.creditoEstado = estados.find(
        //   (el) => el.id === lineaCreditoCliente[0].estado_id
        // ).nombre;
        const [a] = lineaCreditoCliente;
        this.lineaCreditoCliente = a;
        this.desembolso = this.lineaCreditoCliente.fecha_desembolso;
        this.isLiquidado = liquidado;
      }
      if (status === 'error ') console.error(message);
    },
    async getEstadoMora() {
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const url = `${API_URL}api/solicitudEstadomora/${this.legajo}`;

      await axios
        .get(url, header)
        .then((r) => {
          const { data } = r;

          this.estadoMora = data.estados.estado;

          if (data.estados.length === 0) {
            return;
          }

          this.tipoMora = data.estados.tipomora_id
            ? data.estados.tipomora_id.definicion
            : '';
          this.diasatraso = data.estados.diasatraso;
          this.saldocapital = data.estados.saldocapital
            ? this.formatter.format(
                parseFloat(data.estados.saldocapital.toFixed(2))
              )
            : 0;
          if (data.estados.estadosmanuales_id !== 1) {
            this.estadoManual = data.estados.estadosmanuales_id
              ? data.estados.estadosmanuales_id.definicion
              : '';
            this.observaciones = data.estados.observacion;
          }
        })
        .catch((e) => console.log(e));
    },
    async crearEstado() {
      const tipoEstado = this.values;
      const { selectObservacion } = this;
      if (!tipoEstado) {
        return this.$swal(
          'Atención',
          'Debe seleccionar un tipo estado',
          'info'
        );
      }
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const url = `${API_URL}api/creditomora/addestadomanual`;
      const data = {
        legajo: Number(this.legajo),
        estadoManual: tipoEstado,
        observacion: selectObservacion,
        estado: this.estadoMora,
        dias: this.diasatraso,
      };
      await axios
        .post(url, data, header)
        .then((r) => {
          const { data: resp } = r;
          showSimpleMessage('OK', 'Estado guardado', 'success', 2000);

          this.estadoManual = tipoEstado;
          this.observaciones = resp.data.observacion;
          this.values = '';
          this.selectObservacion = '';
          this.dialog = false;
        })
        .catch((e) => {
          console.error(e);
          return showSimpleMessage(
            'Error',
            'Ocurrió un error al crear el estado',
            'error',
            2000
          );
        });
    },
    abrirModal() {
      this.dialog = true;
    },
    cerrarModal() {
      this.dialog = false;
    },
  },
  created() {
    this.loadParametros();
    this.getEstado();
    this.getFechaActual();
    this.cargarCuadro();
    this.getLineaCredito();
    this.getEstadoCredito();
    this.getEstadoMora();
  },
};
</script>
<style>
.operatoria-color {
  color: rgb(74, 77, 96);
}
.estado-manual {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 135px;
  background-color: #f5f5f5;
}
.titulo {
  font-size: 20px;
}
.titulo-nombre {
  font-size: 20px;
  margin-left: 3px;
}
.titulo-descrip {
  font-size: 18px;
  margin-left: 1px;
}
</style>
