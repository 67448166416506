<template>
  <div>
    <div>
      <v-simple-table class="my-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th>Neto</th>
              <th>IVA</th>
              <th>IIBB</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Honorarios MFSA</td>
              <td>{{ formatCurrency(honorariosMfs.toFixed(2)) }}</td>
              <td>{{ formatCurrency(honorariosMfsIva.toFixed(2)) }}</td>
              <td>{{ formatCurrency(iibbHonorariosMfs.toFixed(2)) }}</td>
              <td>{{ formatCurrency(totalHonorariosMfs.toFixed(2)) }}</td>
            </tr>
            <tr>
              <td>Honorarios escribano</td>
              <td>{{ formatCurrency(otrosGastos.toFixed(2)) }}</td>
              <td>{{ formatCurrency(ivaOtrosGastos.toFixed(2)) }}</td>
              <td>
                {{ formatCurrency(iibbCertificacionesNotariales.toFixed(2)) }}
              </td>
              <td>{{ formatCurrency(totalOtrosGastos.toFixed(2)) }}</td>
            </tr>
            <tr>
              <td>Ingresos Brutos</td>
              <td>
                {{ formatCurrency(certificacionesNotariales.toFixed(2)) }}
              </td>
              <td>
                {{ formatCurrency(certificacionesNotarialesIva.toFixed(2)) }}
              </td>
              <td>
                {{ formatCurrency(iibbOtrosGastos.toFixed(2)) }}
              </td>
              <td>
                {{ formatCurrency(iibbOtrosGastos.toFixed(2)) }}
              </td>
            </tr>
            <tr>
              <td><b>Totales</b></td>
              <td>
                <b> {{ formatCurrency(totalNeto.toFixed(2)) }}</b>
              </td>
              <td>
                <b> {{ formatCurrency(totalIva.toFixed(2)) }}</b>
              </td>
              <td>
                <b> {{ formatCurrency(totalIibb.toFixed(2)) }}</b>
              </td>
              <td>
                <b> {{ formatCurrency(totalGeneral.toFixed(2)) }}</b>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="centered-table">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="table-header text-left">Monto Credito</th>
              <th class="table-header text-left">Total Liquidación</th>
              <!-- <th class="text-left">Reintegro Efectivo</th> -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table-cell text-left">
                {{ formatCurrency(parseFloat(total).toFixed(2) || 0) }}
              </td>
              <td class="table-cell text-left">
                {{
                  formatCurrency(parseFloat(dataTable.efectivo).toFixed(2) || 0)
                }}
              </td>
              <!-- <td>$ {{ parseFloat(dataTable.efectivo).toFixed(2) || 0 }}</td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  name: 'LiquidacionFinanciamiento',
  props: ['legajo', 'reRender'],
  data: () => ({
    dataTable: { efectivo: 0 },

    liquidaciones: [],
    existeAnticipo: false,
    gastos: [],
    total: 0,
    totalGastos: 0,
    honorariosMfs: 0,
    honorariosMfsIva: 0,
    iibbHonorariosMfs: 0,
    totalHonorariosMfs: 0,

    certificacionesNotariales: 0,
    certificacionesNotarialesIva: 0,
    iibbCertificacionesNotariales: 0,
    totalCertificacionesNotariales: 0,

    otrosGastos: 0,
    totalOtrosGastos: 0,
    ivaOtrosGastos: 0,
    iibbOtrosGastos: 0,

    totalNeto: 0,
    totalIva: 0,
    totalIibb: 0,
    totalGeneral: 0,

    totalFinanciero: 0,
  }),

  methods: {
    formatCurrency(value) {
      const numericValue = parseFloat(value);
      if (isNaN(numericValue)) {
        return value;
      }
      const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
      });

      return formatter.format(numericValue);
    },
    calcularTotales() {
      this.gastos.forEach((g) => {
        this.honorariosMfs =
          g.descripcion === 'Gastos Administrativos'
            ? this.honorariosMfs + parseFloat(g.administrativos.administrativo)
            : this.honorariosMfs;

        this.iibbHonorariosMfs =
          g.descripcion === 'Gastos Administrativos'
            ? this.iibbHonorariosMfs + parseFloat(g.iibb)
            : this.iibbHonorariosMfs;

        this.honorariosMfsIva =
          g.descripcion === 'Gastos Administrativos'
            ? this.honorariosMfsIva + parseFloat(g.administrativos.iva)
            : this.honorariosMfsIva;

        this.totalHonorariosMfs =
          this.honorariosMfs + this.honorariosMfsIva + this.iibbHonorariosMfs;
        //--------
        this.otrosGastos =
          g.descripcion === 'Otorgamiento'
            ? this.otrosGastos + parseFloat(g.administrativos.otorgamiento)
            : this.otrosGastos;

        this.ivaOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? this.ivaOtrosGastos + parseFloat(g.administrativos.iva)
            : this.ivaOtrosGastos;

        this.iibbOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? this.iibbOtrosGastos + parseFloat(g.iibb)
            : this.iibbOtrosGastos;

        this.totalOtrosGastos = this.otrosGastos + this.ivaOtrosGastos;

        this.totalGeneral =
          this.totalHonorariosMfs +
          this.totalOtrosGastos +
          this.iibbOtrosGastos;
        this.totalIibb = this.iibbHonorariosMfs + this.iibbOtrosGastos;
        this.totalIva = this.honorariosMfsIva + this.ivaOtrosGastos;
        this.totalNeto = this.otrosGastos + this.honorariosMfs;
      });
    },
    async getLiquidaciones() {
      // this.loadingLiquidar = true;
      try {
        const query = {
          method: 'GET',
          url: `${API_URL}api/desembolso/liquidaciones/${this.legajo}`,
          headers: {
            'Content-Type': 'application/json',
            token: localStorage.getItem('token'),
          },
        };

        const response = await axios(query);
        this.liquidaciones = response.data.liquidaciones;
        this.existeAnticipo = this.liquidaciones
          .map(({ anticipo }) => anticipo)
          .includes(true);

        if (this.liquidaciones.length > 0) {
          let sum = 0;
          this.gastos.forEach((g) => {
            sum =
              sum +
              parseFloat(g.iibb) +
              parseFloat(g.administrativos.iva) +
              parseFloat(g.administrativos.otorgamiento) +
              parseFloat(g.administrativos.administrativo);
          });

          this.totalGastos = sum;
          const { efectivo } = this.liquidaciones[0];
          this.dataTable = {
            efectivo,
          };
        }
      } catch (error) {
        console.error('Error fetching liquidaciones:', error);
        // Handle error appropriately
      }
    },
    async getGastos() {
      try {
        const desembolsoId = '0';
        const legajo = this.legajo.toString();
        const query = {
          method: 'get',
          // url: `${API_URL}api/gastos/cc`,
          url: `${API_URL}api/v1/gastos/legajo/${legajo}/desembolso/${desembolsoId}`,
          headers: {
            token: this.token,
          },
        };
        const {
          data: { status, response },
        } = await axios(query);

        if (status === 'success') {
          this.gastos = response;
          this.calcularTotales();
        }
        if (status === 'error') {
          console.log(`Error ${message} statusCode: ${statusCode}`);
        }
        // this.reRender += 1;
      } catch (error) {
        console.error(error);
      }
    },
    async getMonto() {
      const url = `${API_URL}api/clienteParametros`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        lineaCreditoCliente_id: this.legajo,
      };
      axios.post(url, data, header).then((response) => {
        const clienteParametro = response.data.ClienteParametro;
        // Validando por tipo de operatoria/categoria (tipo_servicio_id) Programa ENLACE

        if (clienteParametro[0].tipo_parametro_id.tipo_servicio_id === 13) {
          this.total = clienteParametro.filter(
            (el) => el.tipo_parametro_id.clave === 'monto'
          )[0].valor;
        }

        if (response.data.status === 'error') {
          this.$swal.fire(
            'Ocurrió un error',
            'No se pudieron obtener los parametros ingresados por el cliente',
            'warning'
          );
        }
      });
    },
    async fetchData() {
      await this.getLiquidaciones();
      await this.getGastos();
      await this.getMonto();
    },
  },
  watch: {
    reRender(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData(); // Call your data fetching function
        this.calcularTotales();
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style>
.my-table {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.my-table th,
.my-table td {
  padding: 10px;
  border: 1px solid #d32727;
  text-align: left;
}

.my-table th {
  font-weight: bold;
}

.my-table .text-right {
  text-align: right;
}

.table-header {
  font-weight: bold;
  text-align: left;
  padding: 10px; /* Adjust padding for desired spacing */
}

.table-cell {
  text-align: left;
  padding: 5px; /* Adjust padding for desired spacing */
}
.centered-table {
  display: flex;
  justify-content: center;
}
</style>
